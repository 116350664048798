import {createBrowserRouter } from "react-router-dom";

import Layout from "./layout/Layout";
import NotFound from "./views/NotFound";
import OmnisList from "./views/OmnisList";

const router = createBrowserRouter([
    {
        path: '/',
        element: <Layout />,
        children: [
            {
                path: '/',
                element: <OmnisList />
            }
        ],
    },
    {
        path: '*',
        element: <NotFound />
    }
])

export default router;