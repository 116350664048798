
export default function Footer () {
  return ( 
    <div className="phsoftware-footer text-muted">
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12 phsoftware-footer">
          &nbsp;&nbsp;&nbsp;<img src="/images/PHSoftware_LogoOnly.png" style={{"width":"15px"}} alt=""/>
          <small>&nbsp;&nbsp;Website hosted by &copy; PHSoftware.</small>
        </div>
      </div>
      </div>
    </div>
   );
}