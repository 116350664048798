import {  Outlet } from "react-router-dom"; //Navigate,

import Header from "./components/Header";
import Footer from "./components/Footer";

export default function Layout() {
  return ( 
    <>
      <Header />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 phsoftware-main">
              <Outlet />
            </div>
          </div>
        </div>

      <Footer />
    </>
   );
}
