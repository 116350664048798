import { FormEvent, useEffect, useRef, useState } from "react"
import axiosClient from "../axios-client"

interface emailEntryInterface {
  id:           number,
  emessageid:   string,
  efrom:        string,
  esubject:     string,
  edate:        string
}
type emailListType = emailEntryInterface[];

interface FuncProps {
  setFoundList: (data: emailListType) => void;
}


interface reCaptchaErrors {
  message: string
  score: number
  code: number
}

declare global {
  interface Window {
    grecaptcha: ReCaptchaInstance
    captchaOnLoad: () => void
  }
}

interface ReCaptchaInstance {
  ready: (cb: () => any) => any
  execute: (site_key: string, options: ReCaptchaExecuteOptions) => Promise<string>
  render: (id: string, options: ReCaptchaRenderOptions) => any
}

interface ReCaptchaExecuteOptions {
  action: string
}

interface ReCaptchaRenderOptions {
  sitekey: string
  size: 'invisible'
}

export default function Search (props: FuncProps) {

  const [errors, setErrors] = useState<reCaptchaErrors>({message:'',score:0,code:0});
  const [greCaptchaLoaded, setGreCaptchaLoaded] = useState<boolean>( false );

  const searchRef = useRef<HTMLInputElement>(null);
  const fromRef = useRef<HTMLInputElement>(null);
  const dateFromRef = useRef<HTMLInputElement>(null);
  const dateToRef = useRef<HTMLInputElement>(null);

  const [emailListData, setEmailListData] = useState<emailListType>([]);

  useEffect (() => {
    if (emailListData)
    {
      props.setFoundList(emailListData);
    }
  },[emailListData])

  useEffect(() => {
    const loadScriptByURL = (id:string, url:string, callback:()=>void) => {
        var isScriptExist = document.getElementById(id);
    
        if (!isScriptExist) {
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.src = url;
            script.id = id;
            script.async = true;
            script.defer = true;
            script.onload = function () {
                setGreCaptchaLoaded(true);
                if (callback) callback();
            };
            document.body.appendChild(script);
        }
    }
    
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY}`, function () {
    });
  },[]);
  /* script now embedded */


  const onSubmit = (ev:FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    try {
        window.grecaptcha.execute(`${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY}`,{action: 'contact'})
            .then( function (token) {
                SubmitQuery(token);
            });
        }
    catch (err)
    {
        console.error('Error executing reCAPTCHA:', err);
        // setErrors({message:'Error executing reCAPTCHA',score:0,code:0});
    }
  };


    /* On completion of grecaptcha script running, then run the actual submit */
    const SubmitQuery = (token:string) => {

      const payload = {
          formReCaptchaSearch: token,
          search: searchRef?.current?.value,
          from: fromRef?.current?.value,
          dateFrom: dateFromRef?.current?.value,
          dateTo: dateToRef?.current?.value,
      }

      //  console.log(payload);
      
      axiosClient.post('/search', payload)
      .then(({data})=> {
        // console.log(data);
        setEmailListData(data.data);
      })
      .catch(err => {
          const response = err.response;
          if (response && ((response.status === 422)||(response.status === 418))) { // Validation error!
              if (response.data.errors){
                  console.error('Error executing reCAPTCHA:', err);
                  // setErrors(response.data.errors)
              } else {
                  console.error('Error executing reCAPTCHA:', err);
                  setErrors(response.data.message)
              }
          }
      })
  }

  return ( 
    <div className="col-lg-12 text-start">
        <div className="row">
            <div className="col-lg-12 col-sm-12">
                <div className="enquiryform">  
                    <form onSubmit={onSubmit} id="contact-form">
                        {errors.message && 
                        <div className="phsoftware-alert">
                            {Object.keys(errors).map(message => (
                                <p key={message}>{[message][0]}</p>
                            ))}
                        </div>
                        }
                        <div className="row">
                          <div className="col-lg-11 col-sm-11">
                            <div className="input-group">
                              <label className="col-lg-1 col-sm-1" htmlFor="Search">Search :</label>
                              <input ref={searchRef} type="text" className="form-control" name="search" id="search" defaultValue="" placeholder="" />
                            </div>
                          </div>
                          {greCaptchaLoaded &&
                          <div className="col-lg-1 col-sm-1">
                            <div className="form-group text-center">
                                <button type="submit" className="btn btn-primary">&nbsp;&nbsp;&nbsp;Search&nbsp;&nbsp;&nbsp;</button>
                            </div>
                          </div>
                        }
                        </div>
                        <div className="row">
                          <div className="col-lg-4 col-sm-4">
                            <div className="input-group">
                              <label className="col-lg-2 col-sm-2" htmlFor="from">From:</label>
                              <input ref={fromRef} type="text" className="form-control" name="from" id="from" defaultValue="" placeholder="" />
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-4">
                            <div className="input-group">
                              <label className="col-lg-3 col-sm-3" htmlFor="dateFrom">Date From:</label>
                              <input ref={dateFromRef} type="date" className="form-control" name="dateFrom" id="dateFrom" defaultValue="" placeholder="" />
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-4">
                            <div className="input-group">
                              <label className="col-lg-3 col-sm-3" htmlFor="dateTo">Date To:</label>
                              <input ref={dateToRef} type="date" className="form-control " name="dateTo" id="dateTo" defaultValue="" placeholder="" />
                            </div>
                          </div>
                        </div>


                    </form>
                </div>
            </div>     
        </div>

    </div>
  );
}