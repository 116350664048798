
import {Image} from "react-bootstrap";

export default function NotFound () {
    return ( 
        <div className="row">
        <div className="col-md-4 phsoftware-main">
            <Image src="/images/404.gif" style={{width:"150px"}}/>
        </div>
            <div className="col-md-8 phsoftware-main">
                <h1>404 - Not Found!</h1>
                <p>Sorry, something went very wrong? <br/>The page you are looking for does not exist.</p>
            </div>
        </div>
     );
}