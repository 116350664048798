
export default function Header () {
  return ( 
    <div className="phsoftware-header">
      <div className="container-fluid">
          <div className="row col-md-12">
               <div className="col-md-2">
                    <img src="/images/PHSoftware_LogoOnly.png" style={{"width":"25px", "top":"0"}} alt=""/>
               </div>
               <div className="col-md-3">
                    <h3 className="lead phsoftware-title">Omnis List search</h3>
               </div>
               <div className="col-md-7">
                    <p className="lead phsoftware-description ">A search for emails sent to the Omnis list server since May 2008.</p>
               </div>
         </div>
      </div>
    </div>
   )
}

