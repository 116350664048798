import axios from "axios";

const axiosClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/api`,
  timeout: 4000,
  headers: {
      'Access-Control-Allow-Origin': '*',
  },
})

axiosClient.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('ACCESS_TOKEN');
  config.headers.Authorization = `Bearer ${token}`;
  return config;
})

axiosClient.interceptors.response.use((response) => { // Fulfilled
  return response;
}, (error) => { // rejected
  try {
    const {response} = error;
    if (response.status === 401) {
      sessionStorage.removeItem('ACCESS_TOKEN');
    }
  } catch (e) { console.error(e); }

  throw error;
})

export default axiosClient;